<script>
  import Swal from "sweetalert2";
  import { fade } from "svelte/transition";

  export let isOpen = false;

  let formData = {
    type: "",
    name: "",
    email: "",
    course: "",
    enrolProblem: "",
    device: "",
    otherDevice: "",
    paymentMethod: "",
    contactPermission: "Yes",
    errorMessage: "",
    clientConcern: "",
    others: "",
  };

  const submitForm = async () => {
    if (isFormEmpty()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        html: errorMessage,
      });
      errorMessage = "";
      return;
    }

    let otherDevice;
    if (formData.device == "other" && formData.otherDevice != "") {
      formData.device = formData.otherDevice;
      otherDevice = "other";
    }

    delete formData.otherDevice;

    try {
      let host = "";
      if (process.env.config.environment === "development") {
        host = process.env.config.localServerUrl;
      }
      const response = await fetch(`${host}/api/v2/contact-us`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Form data successfully submitted!",
        });
        if (otherDevice == "other") {
          formData.otherDevice = formData.device;
          formData.device = "other";
        }
        closeModal();
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops!",
          text: "An Error has Occured!",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: "Oops!",
        text: "An Error has Occured!",
      });
    }
  };

  let errorMessage = "";

  function closeModal() {
    isOpen = false;
  }

  function isFormEmpty() {
    if (!formData.name) {
      errorMessage += "<li>Please specify the name!</li>";
    }
    if (!formData.email) {
      errorMessage += "<li>Please specify the email!</li>";
    }
    if (formData.type == "Technical") {
      if (!formData.course) {
        errorMessage += "<li>Please specify the course!</li>";
      }
      if (!formData.enrolProblem) {
        errorMessage += "<li>Please specify the enrol problem!</li>";
      }
      if (!formData.device) {
        errorMessage += "<li>Please specify the device!</li>";
      }
      if (formData.device == "other" && !formData.otherDevice) {
        errorMessage += "<li>Please specify the other device!</li>";
      }
      if (!formData.contactPermission) {
        errorMessage += "<li>Please specify the contact permission!</li>";
      }
    } else if (formData.type == "Affordability") {
      if (!formData.clientConcern) {
        errorMessage += "<li>Please specify your concern!</li>";
      }
    } else if (formData.type == "others") {
      if (!formData.others) {
        errorMessage += "<li>Please specify your message!</li>";
      }
    }

    return errorMessage != "";
  }

  function stopPropagation(event) {
    event.stopPropagation();
  }
</script>

{#if isOpen}
  <div class="modal" style="display: {isOpen ? 'flex' : 'none'};">
    <div
      on:keydown={(event) => {
        if (event.key === "Enter" || event.key === "Space") {
          stopPropagation;
        }
      }}
      on:click={stopPropagation}
      transition:fade
      class="modal-content"
    >
      <div
        style="float: right; cursor: pointer; margin-top: -10px;"
        on:keydown={(event) => {
          if (event.key === "Enter" || event.key === "Space") {
            closeModal();
          }
        }}
        on:click={() => closeModal()}
      >
        ✖
      </div>
      <br /><br />
      <h3>Problems? Tell us more...</h3>
      <p style="margin-bottom: 25px">
        Complete this form or email us at: <a
          target="_blank"
          href="mailto:admin@druidry.org">admin@druidry.org</a
        >
      </p>
      <label for="enrolName">Your Full Name *</label>
      <input
        id="enrolName"
        name="enrolName"
        type="text"
        class="input"
        required
        bind:value={formData.name}
      />
      <label for="enrolEmail">Your Email *</label>
      <input
        id="enrolEmail"
        name="enrolEmail"
        class="input"
        type="email"
        required
        bind:value={formData.email}
      />
      <label for="type">What kind of issue are you having? *</label>
      <div class="dropdown">
        <select required bind:value={formData.type} id="type" name="type">
          <option disabled value="">-- Choose --</option>
          <option value="Technical">Technical</option>
          <option value="Affordability">Affordability</option>
          <option value="others">Other</option>
        </select>
        <div class="chevron">
          <span>&#9662;</span>
        </div>
      </div>
      {#if formData.type == "Technical"}
        <label for="course">What course are you trying to enrol on? *</label>
        <input
          id="course"
          name="course"
          type="text"
          class="input"
          required
          bind:value={formData.course}
        />
        <label for="enrolProblem"
          >What problem with enrolling are you experiencing? *</label
        >
        <textarea
          bind:value={formData.enrolProblem}
          id="enrolProblem"
          name="enrolProblem"
          required
        />

        <label for="device">What device are you using? *</label>
        <div class="dropdown">
          <select
            required
            bind:value={formData.device}
            id="device"
            name="device"
          >
            <option value="" disabled>-- Choose --</option>
            <option value="Desktop">Desktop</option>
            <option value="Laptop">Laptop</option>
            <option value="Mobile">Mobile</option>
            <option value="Tablet">Tablet</option>
            <option value="other">Other</option>
          </select>
          <div class="chevron">
            <span>&#9662;</span>
          </div>
        </div>
        {#if formData.device === "other"}
          <input class="input" bind:value={formData.otherDevice} />
        {/if}
        <label for="paymentMethod">What payment method are you using?</label>
        <div class="dropdown">
          <select
            bind:value={formData.paymentMethod}
            id="paymentMethod"
            name="paymentMethod"
          >
            <option value="" disabled>-- Choose --</option>

            <option value="PayPal">PayPal</option>
            <option value="Credit Card">Credit Card</option>
            <option value="Debit Card">Debit Card</option>
            <option value="Ideal">Ideal</option>
            <option value="SEPA">SEPA</option>
            <option value="GiroPay">GiroPay</option>
            <option value="Bancontact">BanContact</option>
            <option value="Eps">Eps</option>
            <option value="P24">P24</option>
          </select>
          <div class="chevron">
            <span>&#9662;</span>
          </div>
        </div>
        <label for="errorMessage">Any error message?</label>
        <textarea
          bind:value={formData.errorMessage}
          id="errorMessage"
          name="errorMessage"
        />
        <label for="contactPermission"
          >Please can we contact you to help? *</label
        >

        <div style="display:grid">
          <label for="contactPermissionYes" style="margin-top: 10px">
            <input
              type="radio"
              value="Yes"
              bind:group={formData.contactPermission}
              name="contactPermission"
              id="contactPermissionYes"
              class="radio-input"
            /> Yes</label
          >

          <label for="contactPermissionNo">
            <input
              type="radio"
              value="No"
              bind:group={formData.contactPermission}
              name="contactPermission"
              id="contactPermissionNo"
              class="radio-input"
            /> No</label
          >
        </div>
      {:else if formData.type == "Affordability"}
        We are keen that no-one be denied access to the course for reasons of
        financial constraint, therefore we offer a limited number of enrolments
        each month to the online version at a concessionary rate to those in
        need. To be considered, we ask you briefly outline your current
        circumstances.
        <textarea
          bind:value={formData.clientConcern}
          id="clientConcern"
          name="clientConcern"
        />
      {:else if formData.type == "others"}
        <label for="enrolMessage">Your message *</label>
        <input
          id="enrolMessage"
          name="enrolMessage"
          type="text"
          class="input"
          required
          bind:value={formData.others}
        />
      {/if}
      <div class="buttons-container">
        <button class="cancel" on:click|preventDefault={() => closeModal()}
          >Cancel</button
        >
        <button on:click|preventDefault={() => submitForm()}>Submit</button>
      </div>
    </div>
  </div>
{/if}

<style>
  .buttons-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
  }
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow-y: auto;
  }

  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 400px;
    max-height: 80vh;
    overflow-y: auto;
  }

  label {
    margin-bottom: 8px;
  }

  select,
  textarea {
    margin-bottom: 16px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  button {
    padding: 10px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 8px;
  }

  button.cancel {
    background-color: #f44336;
  }

  textarea {
    resize: vertical;
  }

  .dropdown {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  .chevron {
    position: absolute;
    top: 42%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
  }

  .chevron span {
    font-size: 13px;
  }

  .input {
    margin-bottom: 16px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    position: relative;
    display: inline-block;
    width: 100%;
  }

  /* Style for the radio input */
  .radio-input {
    margin-bottom: 12px;
    padding: 12px;
    border: 2px solid #007bff;
    border-radius: 8px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease;
  }

  .radio-label {
    margin-left: 8px;
    vertical-align: middle;
  }
</style>
