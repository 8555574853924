<script>
  import { page } from "@roxi/routify";
  import { _ } from "svelte-i18n";

  const steps = ["package", "details", "payment"];

  let step;
  $: step = $page.title;

  let idx;
  $: idx = steps.indexOf(step);
</script>

{#if idx > -1}
  <ul>
    <li class:active={idx >= 0}>{@html $_("steps.selection")}</li>
    <li class:active={idx >= 1}>{@html $_("steps.your_details")}</li>
    <li class:active={idx >= 2}>{@html $_("steps.payment")}</li>
  </ul>
{/if}

<style>
  @import "../styles/variables.css";

  ul {
    counter-reset: step;
    display: block;
    height: 3rem;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
  li {
    list-style-type: none;
    width: 30%;
    float: left;
    font-size: 12px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    color: var(--text);
  }
  li:before {
    width: 30px;
    height: 30px;
    content: counter(step);
    counter-increment: step;
    line-height: 30px;
    border: 2px solid var(--text);
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    background-color: white;
  }
  li:after {
    width: 100%;
    height: 2px;
    content: "";
    position: absolute;
    background-color: var(--text);
    top: 15px;
    left: -50%;
    z-index: -1;
  }
  li:first-child:after {
    content: none;
  }
  li.active {
    color: var(--primary-light);
  }
  li.active:before {
    border-color: var(--primary-light);
    background-color: var(--primary-light);
    color: white;
  }
  li.active + li:after {
    background-color: var(--primary-light);
  }
</style>
