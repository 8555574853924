<script>
  export let countries,
    price,
    countryCode,
    isOnline,
    companion,
    descriptionText = "";

  import ProductDescription from "../components/ProductDescription.svelte";
  import CountryName from "../components/CountryName.svelte";
  import PriceFormatted from "../components/PriceFormatted.svelte";
  import { _ } from "svelte-i18n";
  import { getCountryName } from "../utils";

  const productName = price?.variant.product.name;
  const isHearth =
    productName.includes("Hearth") || productName.includes("hearth");

  let companionCoursePrice;
  $: {
    if (price) {
      companionCoursePrice = price.currency_code == "GBP" ? 120 : 140;
    }
  }

  let value;
  $: value = Number(price.value) + (companion ? companionCoursePrice : 0);

  let shipping;
  $: shipping = !isOnline;

  function getCountry(countryCode) {
    return countryCode ? getCountryName(countries, countryCode) : "";
  }
</script>

<section>
  <h2>{$_("details.your_order_summary")}</h2>
  {#if !isHearth}
    <p class="margin">{@html descriptionText}</p>
  {/if}
  <p class="margin"><ProductDescription {price} /></p>

  <div class="price">
    <p class="margin">
      {#if !price.value || price.value == "0.00"}
        <strong><PriceFormatted value="0.00" /></strong> (Free Item)
      {:else}
        {$_("details.your_total")}
      {/if}
      <strong>
        {#if price}
          <PriceFormatted {value} currency={price.currency_code} />
        {/if}
      </strong>
    </p>
    {#if isHearth && price.variant.payment_frequency === "annually"}
      <em style="text-align: center">
        per year perpetual subscription.
        <br />
        NOTE: your subscription will renew automatically until cancelled. A reminder
        notification email will be sent prior to renewal charge being applied.
      </em>
    {/if}
    {#if isHearth && price.variant.payment_frequency === "monthly"}
      <em style="text-align: center">
        per month perpetual subscription.
        <br />
        NOTE: your subscription will renew automatically until cancelled. A reminder
        notification email will be sent prior to renewal charge being applied.
      </em>
    {/if}
    {#if shipping && !isHearth}
      <em>
        {#if price.variant.payment_frequency === "monthly"}
          {$_("details.your_price_include_shipping_to_and_monthly", {
            values: {
              countryName: getCountry(countryCode),
              numberOfPayments: price.variant.number_of_payments,
            },
          })}
        {:else}
          {$_("details.your_price_include_shipping_to", {
            values: { countryName: getCountry(countryCode) },
          })}
        {/if}</em
      >
    {/if}
  </div>
</section>

<style>
  @import "../styles/variables.css";

  .price {
    text-align: center;
    font-size: 20px;
    line-height: 20px;
    text-transform: uppercase;
    background: var(--tertiary);
    padding: var(--baseline);
    border-radius: 4px;
  }

  .price em {
    text-transform: none;
    font-size: 11px;
    display: block;
    line-height: 15px;
  }

  .margin {
    margin-bottom: var(--baseline);
  }
</style>
