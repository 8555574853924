<script>
  export let value = null,
    currency = "GBP";

  import { getPriceFormatted } from "../utils.js";

  let priceFormatted;
  $: priceFormatted = value ? getPriceFormatted(value, currency) : "";
</script>

{priceFormatted}
