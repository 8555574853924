<script>
  import zxcvbn from "zxcvbn";
  import { onMount } from "svelte";
  import { url } from "@roxi/routify";
  import CountryName from "./CountryName.svelte";
  import ErrorMessage from "./ErrorMessage.svelte";
  import { _ } from "svelte-i18n";
  import Slider from "./Slider.svelte";
  export let customerDetails,
    countryCode,
    price,
    formSubmit,
    companion,
    countries,
    invitationData = null,
    isMember = false;

  let firstPassword,
    secondPassword,
    firstPasswordField,
    firstPasswordError,
    secondPasswordField,
    secondPasswordError,
    showErrors = false;

  const isOnlineCourse = [
    "online",
    "text_online",
    "bardic_online_offer",
  ].includes(price.variant.product.code);

  var showPassword = isOnlineCourse;

  if (typeof customerDetails === "undefined") customerDetails = {};

  if (isMember) customerDetails.whereHeard = "Member";

  if (localStorage.getItem("hearth-online-user_id")) {
    showPassword = false;
  }

  if (invitationData) {
    customerDetails.firstName = invitationData.FirstName;
    customerDetails.lastName = invitationData.LastName;
    customerDetails.EmailSubStatus = invitationData.EmailSubStatus;
    customerDetails.email = invitationData.Email;
    customerDetails.address1 = invitationData.Address1;
    customerDetails.address2 = invitationData.Address2;
    customerDetails.address3 = invitationData.Address3;
    customerDetails.countryCode = invitationData.CountryCode;
    customerDetails.postCode = invitationData.PostCode;
    customerDetails.county = invitationData.County;
    customerDetails.town = invitationData.Town;
    customerDetails.InviteCode = invitationData.InviteCode;
    customerDetails.new_course = false;
    customerDetails.new_email = false;
    showPassword = false;
    if (
      // new user and course is online
      (invitationData.wordpress_user_id == 0 ||
        !invitationData.wordpress_user_id) &&
      isOnlineCourse
    ) {
      showPassword = true;
    } else if (
      // old user and course is online
      (invitationData.wordpress_user_id > 0 ||
        invitationData.wordpress_user_id) &&
      isOnlineCourse
    ) {
      customerDetails.new_course = true;
    }
  }

  if (companion) {
    (customerDetails.CompanionFirstName = ""),
      (customerDetails.CompanionLastName = ""),
      (customerDetails.CompanionEmail = "");
  }

  function passwordScore(password) {
    if (password && password.length) {
      const { score } = zxcvbn(password);
      return score;
    } else {
      return 0;
    }
  }

  $: {
    let error;
    if (
      firstPassword &&
      firstPassword.length &&
      passwordScore(firstPassword) < 1
    ) {
      error =
        "The password is too easy to guess - try adding some numbers or special characters to the end";
      customerDetails.password = null;
    } else {
      customerDetails.password = firstPassword;
    }

    if (error) {
      firstPasswordField && firstPasswordField.setCustomValidity(error);
      firstPasswordError = error;
    } else {
      firstPasswordField && firstPasswordField.setCustomValidity("");
      firstPasswordError = "Please enter a password";
    }
  }

  $: {
    let error;
    if (firstPassword !== secondPassword) {
      error =
        "Please enter your password twice, and make sure both fields match";
    }

    if (error) {
      secondPasswordField && secondPasswordField.setCustomValidity(error);
      secondPasswordError = error;
    } else {
      secondPasswordField && secondPasswordField.setCustomValidity("");
      secondPasswordError = "Please re-enter the same password";
    }
  }

  onMount(() => {
    firstPassword = secondPassword = customerDetails.password;
  });

  function handleSubmit() {
    if (invitationData) {
      if (
        (invitationData.wordpress_user_id > 0 ||
          invitationData.wordpress_user_id) &&
        isOnlineCourse &&
        customerDetails.email !== invitationData.Email
      ) {
        customerDetails.new_email = true;
      }
    }
    formSubmit({ customerDetails });
  }
</script>

<form on:submit|preventDefault={handleSubmit} class:show-errors={showErrors}>
  <slot name="before" />

  <section>
    {#if !invitationData}
      <h2>{$_("details.please_fill_in_your_details")}</h2>
    {/if}
    <div>
      <label for="details-first-name">{$_("details.First Name")}*</label>
      {#if invitationData}
        <p style="margin-top: 5px"><b>{invitationData.FirstName}</b></p>
      {:else}
        <input
          name="first_name"
          type="text"
          required
          bind:value={customerDetails.firstName}
          id="details-first-name"
          title={$_("details.Please enter your first name")}
        />
      {/if}
      <ErrorMessage>{$_("details.Please enter your first name")}</ErrorMessage>
    </div>

    <div>
      <label for="details-last-name">{$_("details.Last Name")}*</label>
      {#if invitationData}
        <p style="margin-top: 5px">
          <b>{invitationData.LastName}</b>
        </p>
      {:else}
        <input
          name="last_name"
          type="text"
          required
          bind:value={customerDetails.lastName}
          id="details-last-name"
          title={$_("details.Please enter your last name")}
        />
      {/if}
      <ErrorMessage>{$_("details.Please enter your last name")}</ErrorMessage>
    </div>

    <div>
      <label for="details-email">{$_("details.Email")}*</label>
      <input
        name="email"
        type="email"
        required
        bind:value={customerDetails.email}
        id="details-email"
        title={$_("details.Please enter your email address")}
      />
      <ErrorMessage
        >{$_("details.Please enter your email address")}</ErrorMessage
      >
    </div>

    {#if showPassword}
      <div>
        <label for="password1"
          >{$_(
            "details.Choose a password (to access your online course)"
          )}*</label
        >
        <input
          name="email"
          type="password"
          required
          bind:value={firstPassword}
          bind:this={firstPasswordField}
          id="password1"
          title={$_("details.Please enter a password")}
        />
        <ErrorMessage>{$_(`details.${firstPasswordError}`)}</ErrorMessage>
      </div>

      <div>
        <label for="password2">{$_("details.Re-enter password")}*</label>
        <input
          name="email"
          type="password"
          required
          bind:value={secondPassword}
          bind:this={secondPasswordField}
          id="password2"
          title={$_("details.Please re-enter password")}
        />
        <ErrorMessage>{$_(`details.${secondPasswordError}`)}</ErrorMessage>
      </div>
    {/if}

    <div>
      <label for="details-address1">{$_("details.Address 1")}*</label>
      <input
        name="address1"
        type="text"
        required
        bind:value={customerDetails.address1}
        id="details-address1"
        title={$_("details.Please enter the first line of your address here")}
      />
      <ErrorMessage
        >{$_(
          "details.Please enter the first line of address here. You may also use the following two lines if needed."
        )}</ErrorMessage
      >
    </div>

    <div>
      <label for="details-address2">{$_("details.Address 2")}</label>
      <input
        name="address2"
        type="text"
        bind:value={customerDetails.address2}
        id="details-address2"
      />
    </div>

    <div>
      <label for="details-address3">{$_("details.Address 3")}</label>
      <input
        name="address3"
        type="text"
        bind:value={customerDetails.address3}
        id="details-address3"
      />
    </div>

    <div>
      <label for="details-town">{$_("details.Town / City")}*</label>
      <input
        name="town"
        type="text"
        required
        bind:value={customerDetails.town}
        id="details-town"
        title={$_("details.Please enter your town or city here.")}
      />
      <ErrorMessage
        >{$_("details.Please enter your town or city here.")}.</ErrorMessage
      >
    </div>

    {#if localStorage.getItem("enrol-app-locale") !== "de"}
      <div>
        <label for="details-county">{$_("details.County / State")}</label>
        <input
          name="county"
          type="text"
          bind:value={customerDetails.county}
          id="details-county"
        />
      </div>
    {/if}

    <div>
      <label for="details-post-code">{$_("details.Post Code / ZIP")}*</label>
      <input
        name="post_code"
        type="text"
        required
        bind:value={customerDetails.postCode}
        id="details-post-code"
        title={$_("details.Please enter your post code here.")}
      />
      <ErrorMessage
        >{$_("details.Please enter your post code here.")}.</ErrorMessage
      >
    </div>

    <div>
      <div class="label">{$_("details.Country")}</div>
      <div class="field"><CountryName {countries} {countryCode} /></div>
      <div class="change-location">
        {$_("details.not_in?")}
        <CountryName {countries} {countryCode} />?
        <a href={$url("./country")}>{$_("choice.change_my_location")}</a>
      </div>
    </div>
    {#if !isMember}
      <div>
        <label for="details-where-heard"
          >{$_("details.We'd love to hear how you got here!")}</label
        >
        <div class="custom-select">
          <select
            class="select"
            required
            bind:value={customerDetails.whereHeard}
            id="details-where-heard"
            title="Please choose an option"
          >
            <option value="">{$_("details.Select")} ...</option>
            <option>{$_("details.Internet Search")}</option>
            <option>{$_("details.Recommendation")}</option>
            <option>{$_("details.Book")}</option>
            <option>{$_("details.Druidcast")}</option>
            <option>{$_("details.Tea with a Druid")}</option>
            <option>{$_("details.Previously Involved")}</option>
            <option>{$_("details.Event")}</option>
            <option>{$_("details.Other")}</option>
            <option>{$_("details.Prefer not to say")}</option>
          </select>
          <ErrorMessage>{$_("details.Please choose an option.")}</ErrorMessage>
        </div>
      </div>
    {/if}
    {#if companion}
      <br />
      <h2>{$_("details.Your_course_companion")}</h2>
      <div>
        <label for="companion-details-first-name"
          >{$_("details.First Name")}*</label
        >
        <input
          name="companion-first_name"
          type="text"
          required
          bind:value={customerDetails.CompanionFirstName}
          id="companion-details-first-name"
          title={$_("details.Please enter your companion first name")}
        />
        <ErrorMessage
          >{$_("details.Please enter your companion first name")}</ErrorMessage
        >
      </div>

      <div>
        <label for="companion-details-last-name"
          >{$_("details.Last Name")}*</label
        >
        <input
          name="companion-last_name"
          type="text"
          required
          bind:value={customerDetails.CompanionLastName}
          id="companion-details-last-name"
          title={$_("details.Please enter your companion last name")}
        />
        <ErrorMessage
          >{$_("details.Please enter your companion last name")}</ErrorMessage
        >
      </div>

      <div>
        <label for="companion-details-email">{$_("details.Email")}*</label>
        <input
          name="companion-email"
          type="email"
          required
          bind:value={customerDetails.CompanionEmail}
          id="companion-details-email"
          title={$_("details.Please enter your companion email address")}
        />
        <ErrorMessage
          >{$_(
            "details.Please enter your companion email address"
          )}</ErrorMessage
        >
      </div>
      <br /><br />
    {/if}
    <section>
      {#if invitationData}
        <div style="display: flex">
          <p style="margin-top: 10px">
            <b>{@html $_("details.subscribe_to_obod")}</b>
          </p>
          <Slider
            style="width: 50%"
            bind:value={customerDetails.EmailSubStatus}
            leftValue="No"
            rightValue="Yes"
            disabled={!invitationData}
          />
        </div>
        <div style="margin-top: 10px">
          <small>{$_("details.note_about_subscribe_to_obod")}</small>
        </div>
      {/if}
    </section>
  </section>

  <section class="btn-container">
    <div class="btn-container__inside">
      <a class="btn btn--secondary" href={$url("./package")}
        >{$_("choice.back")}</a
      >
      <input
        class="btn btn--primary"
        on:click={() => (showErrors = true)}
        type="submit"
        value={$_("choice.next")}
      />
    </div>
  </section>
</form>

<style>
  @import "../styles/variables.css";

  label {
    display: block;
  }

  .show-errors input:invalid,
  .show-errors select:invalid {
    border-color: var(--secondary);
    background: var(--secondary-light);
  }

  label,
  .label {
    margin-top: var(--baseline);
  }

  .change-location {
    font-size: 12px;
    padding: 0.5em 0 0;
    font-style: italic;
  }
</style>
