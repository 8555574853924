<script>
  export let countryCode = null,
    countries;

  import { getCountryName } from "../utils.js";

  let countryName;
  $: countryName = countryCode ? getCountryName(countries, countryCode) : "";
</script>

{#if countryName == "Germany" && localStorage.getItem("enrol-app-locale") == "de"}
  Deutschland
{:else if countryName == "Netherlands" && localStorage.getItem("enrol-app-locale") == "nl"}
  Netherland
{:else}
  {countryName}
{/if}
