<script>
  export let countryCode, countries;
  import { createEventDispatcher } from "svelte";
  import { _ } from "svelte-i18n";
  const dispatch = createEventDispatcher();
</script>

<form on:submit|preventDefault>
  <section>
    <div class="custom-select">
      <select bind:value={countryCode}>
        {#each countries as country}
          {#if country.name == "Germany" && localStorage.getItem("enrol-app-locale") == "de"}
            <option value={country.code}>Deutschland</option>
          {:else if country.name == "Netherlands"}
            <option value={country.code}>Netherland</option>
          {:else}
            <option value={country.code}>{country.name}</option>
          {/if}
        {/each}
      </select>
    </div>
  </section>

  <section class="btn-container">
    <div class="btn-container__inside">
      <button
        class="btn btn--secondary"
        on:click|preventDefault={() => dispatch("goBack")}>Back</button
      >
      <input
        class="btn btn--primary"
        type="submit"
        value={$_("choice.select country")}
      />
    </div>
  </section>
</form>
