<script>
  export let countries,
    rates,
    symbols,
    price,
    countryCode,
    baseCurrency = "gbp_rates",
    companion = false;

  let visible = false;
  export function toggle() {
    visible = !visible;
  }

  import { getCountryCurrency } from "../utils.js";
  import { _ } from "svelte-i18n";
  import Modal from "./Modal.svelte";
  import PriceFormatted from "./PriceFormatted.svelte";

  const defaultCurrency = getCountryCurrency(countries, countryCode);
  let selectedCurrency = defaultCurrency;

  let companionPrice;
  $: companionPrice = baseCurrency == "gbp_rates" ? 120 : 140;

  let companionValue;
  $: companionValue = companion ? companionPrice : 0;

  let value;
  $: value = price ? Number(price.value) : null;

  let convertedValue;
  $: {
    if (selectedCurrency && value) {
      convertedValue = value * rates[baseCurrency][selectedCurrency];
    } else {
      convertedValue = null;
    }
  }

  const topSymbols = ["USD", "CAD", "AUD", "NZD", "EUR"];
  const ignoreSymbols = ["BTC"];
  const topCurrencies = [];
  for (const code of topSymbols) {
    topCurrencies.push({ code, name: symbols[code] });
  }

  const remainingSymbols = Object.getOwnPropertyNames(symbols).filter(
    (symbol) => !ignoreSymbols.includes(symbol) && !topSymbols.includes(symbol)
  );

  const remainingCurrencies = [];
  for (const code of remainingSymbols) {
    remainingCurrencies.push({ code, name: symbols[code] });
  }
  remainingCurrencies.sort(({ name: name1 }, { name: name2 }) =>
    name1.localeCompare(name2)
  );
</script>

<Modal bind:visible>
  <div class="select">
    <label for="currency-selection">
      <div>{$_("choice.select your currency")}</div>
    </label>
    <div class="custom-select">
      <select id="currency-selection" bind:value={selectedCurrency}>
        <option value={null}>{$_("details.Choose")} ...</option>
        {#each topCurrencies as { code, name }}
          <option value={code}>{name} ({code})</option>
        {/each}
        <option value={null}>--------</option>
        {#each remainingCurrencies as { code, name }}
          <option value={code}>{name} ({code})</option>
        {/each}
      </select>
    </div>
  </div>

  {#if price && convertedValue}
    <div class="prices">
      <div class="price-line">
        {#if companion}
          <PriceFormatted
            value={value + companionValue}
            currency={price.currency_code}
          />
        {:else}
          <PriceFormatted {value} currency={price.currency_code} />
        {/if}
        ≈
        {#if companion}
          <PriceFormatted
            value={(value + companionValue) *
              rates[baseCurrency][selectedCurrency]}
            currency={selectedCurrency}
          />
        {:else}
          <PriceFormatted value={convertedValue} currency={selectedCurrency} />
        {/if}

        <span class="asterisk">*</span>
      </div>
      <div class="disclaimer">
        <span class="asterisk">*</span>
        {$_(
          "choice.The online exchange rates provided are indicative and intended as a guide only."
        )}
      </div>
    </div>
  {/if}

  <button on:click|preventDefault={toggle} class="btn btn--primary">OK</button>
</Modal>

<style>
  @import "../styles/variables.css";

  .select,
  .prices {
    margin-bottom: var(--baseline);
  }

  .prices {
    padding: var(--baseline);
  }

  .prices {
    background: var(--tertiary);
  }

  .price-line {
    text-align: center;
    font-size: 20px;
  }

  .disclaimer {
    font-size: 11px;
    margin-top: 1em;
  }
</style>
