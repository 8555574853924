<div class="error-message">
  <slot />
</div>

<style>
  @import "../styles/variables.css";

  :global .error-message {
    display: none;
    background-color: var(--secondary);
    padding: 5px 10px;
    margin-top: -3px;
    border-radius: 0 0 3px 3px;
    color: var(--secondary-light);
  }

  :global .show-errors :invalid ~ .error-message {
    display: block;
  }
</style>
