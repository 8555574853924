<script>
  import { _ } from "svelte-i18n";

  export let product = {},
    prices,
    details,
    isHearth = false,
    order = [];
  let products;
  $: {
    if (prices) {
      products = Object.values(
        prices.reduce((products, { variant: { product } }) => {
          if (!(product.id in products)) products[product.id] = product;
          return products;
        }, {})
      );
      if (isHearth) {
        selectedProductID = products[0]?.id;
      }
    } else {
      products = [];
    }
  }

  let selectedProductID = product?.id;
  $: product = products.find((product) => product.id === selectedProductID);

  let productList;
  $: productList = products
    .sort((product1, product2) => {
      const order1 = order.indexOf(product1.code);
      const order2 = order.indexOf(product2.code);
      return order1 - order2;
    })
    .filter((product) => product.code in details)
    .map((product) => {
      const {
        description,
        blurb,
        image = true,
        type = "",
      } = details[product.code];
      return {
        product,
        image:
          product.thumbnail ||
          (image && `/images/product-select/${type}${product.code}.jpeg`),
        description:
          product.title !== product.name ? product.title : description,
        open: false,
        blurb: product.description || blurb,
      };
    });

  $: {
    if (isHearth && productList) {
      productList[0].open = true;
    }
  }
  function toggle(i) {
    if (isHearth) return;
    productList[i].open = !productList[i].open;
  }

  function select(id) {
    if (isHearth) return;
    if (id === selectedProductID) {
      selectedProductID = null;
    }
  }
</script>

<ul class="products">
  {#each productList as listItem, i}
    <li>
      <input
        type="radio"
        bind:group={selectedProductID}
        value={listItem.product.id}
        id="format-{i}"
        name="format"
        on:click={select(listItem.product.id)}
      />
      {#if listItem.product.code === "intro"}
        <span class="label-intro" for="format-{i}"
          >{@html $_(listItem.description)}</span
        >
      {:else}
        <label class="label_item" for="format-{i}"
          >{@html $_(listItem.description)}</label
        >
      {/if}
      <button
        class="expander"
        style="background: {// selected and menu not open
        listItem.product.id == selectedProductID && !listItem.open
          ? '#113433'
          : `${isHearth ? '#113433' : 'none'}`} !important;
        {// list is open
        listItem.open
          ? `width: ${isHearth ? '60px' : '130px'}`
          : 'width: 130px'} !important;
        "
        on:click|preventDefault={() => toggle(i)}
      >
        {#if listItem.open}
          <div style="cursor: pointer; float: right; margin-right: 28px;">
            {#if !isHearth}
              −
            {:else}
              <span style="visibility: hidden;">−</span>
            {/if}
          </div>
        {:else if !isHearth}
          <img
            class="more-info"
            alt="more-info"
            style="cursor: pointer"
            src={$_("choice.more-info-url")}
          />
        {:else}
          <img />
        {/if}
      </button>
      <div class="details" class:open={listItem.open}>
        {#if listItem.image}
          <div class="picture">
            <img src={listItem.image} alt="" />
          </div>
        {/if}
        <div class="blurb">{@html $_(listItem.blurb)}</div>
      </div>
    </li>
  {/each}
</ul>

<style>
  @import "../styles/variables.css";

  .products,
  .details img {
    border: 2px solid var(--primary);
    border-radius: 4px;
  }

  li {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 2px solid var(--primary);
  }

  li:last-child {
    border-bottom: none;
  }

  label {
    display: flex;
    align-items: center;
    flex: 1;
    color: var(--primary);
    font-weight: bold;
    margin: 0;
  }

  .label-intro {
    display: flex;
    align-items: center;
    flex: 1;
    color: var(--primary);
    font-weight: bold;
    margin-left: 20px;
  }

  label::before {
    content: "☐";
    font-size: 1.5rem;
    margin: 0 1rem;
  }

  input:checked + label {
    opacity: 1;
    background-color: var(--primary);
    color: var(--white);
  }

  input:checked + label::before {
    content: "☑";
  }

  .expander {
    border: none;
    background: none;

    font-size: 1.5rem;
    font-weight: bold;
    padding: 0.75rem 0;
    width: 130px;

    color: var(--primary);
  }

  input {
    display: none;
  }

  .details {
    width: 100%;
    padding: 0.5rem 1rem 1rem;

    display: none;
    align-items: start;
  }

  .details.open {
    display: flex;
  }

  .details img {
    max-width: 200px;
    margin-right: 1rem;
  }

  .details .blurb {
    text-align: justify;
  }

  @media (max-width: 45rem) {
    .details {
      flex-direction: column-reverse;
      align-items: center;
    }

    .details img {
      width: 100%;
      height: auto;
      margin-right: 0;
      margin-top: 1rem;
    }

    .details .blurb {
      hyphens: auto;
    }
  }
</style>
