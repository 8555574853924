<script>
  export let price;

  import { getProductDescription, getProduct } from "../utils.js";
  import { _ } from "svelte-i18n";

  let product;
  $: product = getProduct(price);

  let productDescription;
  $: {
    if (price) {
      if (price.variant.product.title != price.variant.product.name) {
        productDescription = price.variant.product.title;
      } else {
        productDescription =
          (price.variant.product.name.indexOf("Bardic") === 0 ||
            price.variant.product.name.includes("Bardic")) &&
          price.variant.product.code != "bardic_online_offer"
            ? $_(`products.${getProductDescription(product)}`)
            : getProductDescription(product);
      }
    }
  }
</script>

{productDescription}
